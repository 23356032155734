<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-row class="p-2">
        <b-col>
          <h4>Products</h4>
        </b-col>
      </b-row>

      <b-table
        class="position-relative"
        :items="products"
        responsive
        show-empty
        empty-text="No matching records found"
      />

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
}
</script>
