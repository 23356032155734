import { ref } from '@vue/composition-api'
import XLSX from 'xlsx'
import router from '@/router'
import store from '@/store'

export default function useRetailerCatalogueCreate() {
  const templateData = [
    {
      isWeightable: 'N',
      PLU: null,
      askPriceFlag: 'N',
      IsTaxable: 'Y',
      price: 15,
      applicableInventoryTransactionFlag: 'Y',
      isAvailableOnPOS: 'Y',
      isAvailableOnEcom: 'Y',
      POSDescription: 'Test Product 1',
    },
    {
      isWeightable: 'N',
      PLU: null,
      askPriceFlag: 'N',
      IsTaxable: 'N',
      price: 25,
      applicableInventoryTransactionFlag: 'Y',
      isAvailableOnPOS: 'Y',
      isAvailableOnEcom: 'Y',
      POSDescription: 'Test Product 2',
    },
  ]

  const catalogueData = ref([])

  const catalogueTableData = ref([])

  const downloadTemplate = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(templateData)
    XLSX.utils.book_append_sheet(wb, ws, 'retailer-catalogue-template')
    XLSX.writeFile(wb, 'download-retailer-catalogue-template.csv')
  }

  const resetCatalogueData = () => {
    catalogueData.value = JSON.parse(JSON.stringify([]))
    catalogueTableData.value = []
  }

  const validateRetailerCatalogue = async () => {
    try {
      const validateParams = {
        branchId: router.currentRoute.params.id,
        catalogs: catalogueData.value,
      }
      const response = await store.dispatch('app-retailer-catalogue/validateRetailerCatalogue', validateParams)
      catalogueTableData.value = response.data.data
      return response
    } catch (error) {
      return error
    }
  }

  const uploadRetailerCatalogue = async () => {
    try {
      const uploadParams = {
        branchId: router.currentRoute.params.id,
        catalogs: catalogueData.value,
      }
      const response = await store.dispatch('app-retailer-catalogue/uploadRetailerCatalogue', uploadParams)

      if (response.status) {
        resetCatalogueData()
      }

      return response
    } catch (error) {
      return error
    }
  }

  const findCatalogue = categoryId => catalogueData.value.find(obj => obj.category_id === categoryId)

  const findCatalogueIndex = categoryId => catalogueData.value.findIndex(obj => obj.category_id === categoryId)

  const appendCatalogueData = (event, params) => {
    try {
      const selectedCatalogue = findCatalogue(params.category_id)
      if (selectedCatalogue) {
        // eslint-disable-next-line prefer-destructuring
        selectedCatalogue.catalog = event.target.files[0]
        validateRetailerCatalogue()
        return
      }

      catalogueData.value.push({
        category_id: params.category_id,
        catalog: event.target.files[0],
      })

      validateRetailerCatalogue()
    } catch (error) {
      console.error(error)
    }
  }

  const removeCatalogueData = categoryId => {
    if (findCatalogueIndex(categoryId) == -1) {
      return true
    }

    catalogueData.value.splice(findCatalogueIndex(categoryId), 1)

    if (catalogueData.value && catalogueData.value.length == 0) {
      catalogueTableData.value = []
    }

    validateRetailerCatalogue()
    return true
  }

  return {
    templateData,
    catalogueData,
    catalogueTableData,

    downloadTemplate,
    appendCatalogueData,
    removeCatalogueData,
    findCatalogue,
    resetCatalogueData,
    validateRetailerCatalogue,
    uploadRetailerCatalogue,
  }
}
