<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        ref="refSubCategoryListTable"
        class="position-relative"
        :items="subcategories"
        responsive
        :fields="uploadTableColumns"
        primary-key="subcategory_id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="deleteSubCategory(data.item.subcategory_id, 1)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: download -->
        <template #cell(download)="data">
          <b-button
            v-if="subcategories && subcategories.length > 0"
            variant="primary"
            size="sm"
            @click="downloadTemplate"
          >
            Download Template
          </b-button>
        </template>

        <!-- Column: upload -->
        <template #cell(upload)="data">
          <b-button
            v-if="subcategories && subcategories.length > 0"
            size="sm"
            @click="$refs[data.item.subcategory_id].$el.querySelector('input[type=file]').click()"
          >
            Upload CSV
          </b-button>

          <b-button
            v-if="findCatalogue(data.item.subcategory_id)"
            v-ripple.400="'rgba(255, 67, 60, 0.15)'"
            variant="flat-danger"
            class="btn-icon mx-lg-1"
            size="sm"
            @click="removeCatalogueData(data.item.subcategory_id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <b-form-file
            :ref="data.item.subcategory_id"
            class="d-none"
            accept=".csv"
            @change="appendCatalogueData($event, { category_id: data.item.subcategory_id, branchId: $route.params.id })"
          />

          <br>
          {{ (catalogueData && catalogueData.length > 0) && findCatalogue(data.item.subcategory_id) ? findCatalogue(data.item.subcategory_id).catalog.name : '' }}
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BButton, BFormFile, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useSubCategoriesList from './useSubCategoriesList'
// import useCategoriesList from '../useCategoriesList'
import useRetailerCatalogueCreate from '../../../retailer-catalogue/retailer-catalogue-create/useRetailerCatalogueCreate'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    subcategories: {
      type: Array,
      required: true,
    },
    catalogueData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { uploadTableColumns } = useSubCategoriesList()

    // const { resolveStatus, resolveStatusVariant } = useCategoriesList()

    const { downloadTemplate } = useRetailerCatalogueCreate()

    const findCatalogue = categoryId => props.catalogueData.find(obj => obj.category_id === categoryId)

    const refetchData = () => {
      emit('refetch-data')
    }

    const appendCatalogueData = (event, params) => {
      emit('append-catalogue-data', event, params)
    }

    const removeCatalogueData = categoryId => {
      emit('remove-catalogue-data', categoryId)
    }

    const deleteSubCategory = (categoryId, type) => {
      emit('delete-category', categoryId, type)
    }

    return {
      uploadTableColumns,

      deleteSubCategory,
      refetchData,
      downloadTemplate,
      appendCatalogueData,
      removeCatalogueData,
      findCatalogue,

      //   UI
      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>
