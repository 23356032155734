import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
// import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useCategoriesList() {
  // Use toast
  const toast = useToast()

  const refCategoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'description', sortable: false },
    { key: 'status', sortable: false },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  const uploadTableColumns = [
    { key: 'actions', sortable: false },
    { key: 'description', sortable: false },
    { key: 'status', sortable: false },
    { key: 'download', sortable: false },
    { key: 'upload', sortable: false },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('category_id')
  const isSortDirDesc = ref(true)
  const branchId = ref(router.currentRoute.params.id)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoryListTable.value ? refCategoryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refCategoryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('app-retailer-catalogue/fetchCategories', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        branchId: branchId.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // Call System Codes
  // const { callStatus } = useSystemCodes()
  // callStatus()

  return {
    fetchCategories,
    tableColumns,
    uploadTableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoryListTable,
    refetchData,
  }
}
