import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function useCategoriesForm() {
  const blankCategoryData = {
    operation: 'C',
    description: '',
    isSubCategory: false,
    category_id: '',
    branchId: '',
    status: 'C',
  }

  const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchCategories = params => store.dispatch('app-retailer-catalogue/fetchCategories', params)

  const categoryOptions = computed(() => store.getters['app-retailer-catalogue/categoryOptions'])

  // *===============================================---*
  // *--------- ACTIONS ---------------------------------------*
  // *===============================================---*

  const resetCategoryData = () => {
    categoryData.value = JSON.parse(JSON.stringify(blankCategoryData))
  }

  const procSubCategory = async params => {
    try {
      const response = await store.dispatch('app-retailer-catalogue/procSubCategory', params)

      if (!response.status) {
        return false
      }
      return true
    } catch (error) {
      return error
    }
  }

  const procCategory = async params => {
    try {
      const response = await store.dispatch('app-retailer-catalogue/procCategory', params)

      if (!response.status) {
        return false
      }

      return true
    } catch (error) {
      return error
    }
  }

  return {
    //  Data
    categoryData,

    fetchCategories,
    procSubCategory,
    procCategory,
    resetCategoryData,

    // Options
    categoryOptions,
  }
}
