<template>
  <div>

    <b-row>

      <!-- Is Sub Category -->
      <b-col sm="6">
        <b-form-group
          label="Is Sub Category?"
          label-for="is-sub-category"
        >
          <b-form-checkbox
            id="is-sub-category"
            v-model="categoryData.isSubCategory"
            checked="true"
            name="is-sub-category"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>

      <!-- Description -->
      <b-col sm="6">
        <validation-provider
          #default="validationContext"
          name="Description"
          rules="required"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="categoryData.description"
              :state="getValidationState(validationContext)"
              placeholder="Description"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Category -->
      <b-col
        v-if="categoryData.isSubCategory"
        sm="6"
      >
        <validation-provider
          #default="validationContext"
          name="Category"
          rules="required"
        >
          <b-form-group
            class="mb-1"
            label="Category"
            label-for="category"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="categoryData.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              class="w-100"
              :reduce="val => val.value"
              input-id="category"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import useCategoriesForm from './useCategoriesForm'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,

    vSelect,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    categoryData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { getValidationState } = formValidation()

    const { categoryOptions } = useCategoriesForm()

    return {
      //  Form
      getValidationState,

      // Options
      categoryOptions,
    }
  },
}
</script>
