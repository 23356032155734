<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(create)"
        @reset.prevent="resetForm"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <h4>Category & Sub Category</h4>
          </b-col>
          <b-col sm="4">
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <categories-form :category-data="categoryData" />

      </b-form>

    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import router from '@/router'
import CategoriesForm from '../categories-form/CategoriesForm.vue'
import useCategoriesForm from '../categories-form/useCategoriesForm'
import retailerCatalogueStoreModule from '../../retailerCatalogueStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    CategoriesForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup(props, { emit }) {
    const RETAILER_CATALOGUE_APP_STORE_MODULE_NAME = 'app-retailer-catalogue'

    // Register module
    if (!store.hasModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME, retailerCatalogueStoreModule)

    const {
      categoryData,

      fetchCategories,
      procSubCategory,
      procCategory,
      resetCategoryData,
    } = useCategoriesForm()

    fetchCategories({ branchId: router.currentRoute.params.id })

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetCategoryData)

    async function create() {
      try {
        categoryData.value.branchId = router.currentRoute.params.id
        if (categoryData.value.isSubCategory) {
          procSubCategory(categoryData.value) && emit('refetch-data')
          resetForm()
          return
        }
        procCategory(categoryData.value) && emit('refetch-data')
        resetForm()
      } catch (error) {
        console.error(error)
      }
    }

    return {
      //  Data
      categoryData,

      //  Action
      create,

      refFormObserver,
      resetForm,
    }
  },
}
</script>
