<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!--  Refresh Table -->
          <b-col
            class="d-flex align-items-center justify-content-end"
            sm="6"
          >
            <b-button
              v-ripple.400="'rgba(20, 217, 146, 0.15))'"
              variant="outline-secondary"
              class="float-right"
              size="sm"
              @click="refetchData"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                class="mr-50"
              />
              <span>Refresh</span>
            </b-button>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refCategoryListTable"
        class="position-relative"
        :items="fetchCategories"
        responsive
        :fields="uploadTableColumns"
        primary-key="category_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="initiateDelete(data.item.category_id, 0)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="data">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="data.toggleDetails"
          >
            <feather-icon :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">

          <!-- Sub Categories Table -->
          <sub-categories-list
            :subcategories="data.item.subcategories"
            :catalogue-data="catalogueData"
            @refetch-data="refetchData"
            @delete-category="initiateDelete"
            @append-catalogue-data="appendCatalogueData"
            @remove-catalogue-data="removeCatalogueData"
          />

        </template>

        <!-- Column: download -->
        <template #cell(download)="data">
          <b-button
            v-if="data.item.subcategories && data.item.subcategories.length == 0"
            variant="primary"
            size="sm"
            @click="downloadTemplate"
          >
            Download Template
          </b-button>
        </template>

        <!-- Column: upload -->
        <template #cell(upload)="data">
          <b-button
            v-if="data.item.subcategories && data.item.subcategories.length == 0"
            size="sm"
            @click="$refs[data.item.category_id].$el.querySelector('input[type=file]').click()"
          >
            Upload CSV
          </b-button>

          <b-button
            v-if="findCatalogue(data.item.category_id)"
            v-ripple.400="'rgba(255, 67, 60, 0.15)'"
            variant="flat-danger"
            class="btn-icon mx-lg-1"
            size="sm"
            @click="removeCatalogueData(data.item.category_id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <b-form-file
            :ref="data.item.category_id"
            class="d-none"
            accept=".csv"
            @change="appendCatalogueData($event, { category_id: data.item.category_id, branchId: $route.params.id })"
          />
          <br>
          {{ (catalogueData && catalogueData.length > 0) && findCatalogue(data.item.category_id) ? findCatalogue(data.item.category_id).catalog.name : '' }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <confirm-modal
      :ref="'confirm-modal-component'"
      @confirm="deleteCategory"
    >
      <template v-slot:message>
        Are you sure you want to delete this category?
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BBadge, BFormFile, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import useCategoriesList from './useCategoriesList'
import retailerCatalogueStoreModule from '../../retailerCatalogueStoreModule'
import SubCategoriesList from './subcategories-list/SubCategoriesList.vue'
import useRetailerCatalogueCreate from '../../retailer-catalogue/retailer-catalogue-create/useRetailerCatalogueCreate'
import ConfirmModal from '@/components/modals/confirm-modal/ConfirmModal.vue'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    SubCategoriesList,
    ConfirmModal,
  },
  directives: {
    Ripple,
  },
  props: {
    catalogueData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { refs, emit }) {
    const RETAILER_CATALOGUE_APP_STORE_MODULE_NAME = 'app-retailer-catalogue'

    // Register module
    if (!store.hasModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME, retailerCatalogueStoreModule)

    const {
      fetchCategories,
      uploadTableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryListTable,
    } = useCategoriesList()

    const selectedId = ref(null)
    const selectedType = ref(null)

    const initiateDelete = (categoryId, type) => {
      refs['confirm-modal-component'].$refs['confirm-modal'].show()
      selectedId.value = categoryId
      selectedType.value = type
    }

    const { downloadTemplate } = useRetailerCatalogueCreate()

    const findCatalogue = categoryId => props.catalogueData.find(obj => obj.category_id === categoryId)

    const refetchData = categoryId => {
      emit('refetch-data', categoryId)
    }

    const appendCatalogueData = (event, params) => {
      emit('append-catalogue-data', event, params)
    }

    const removeCatalogueData = categoryId => {
      emit('remove-catalogue-data', categoryId)
    }

    const deleteCategory = () => {
      emit('delete-category', selectedId.value, selectedType.value)
    }

    return {
      fetchCategories,
      uploadTableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryListTable,
      refetchData,

      deleteCategory,
      downloadTemplate,
      appendCatalogueData,
      removeCatalogueData,
      findCatalogue,
      initiateDelete,

      //  UI
      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
