export default function useSubCategoriesList() {
  // Table Handlers
  const uploadTableColumns = [
    { key: 'actions', sortable: false },
    { key: 'description', sortable: true },
    { key: 'status', sortable: true },
    { key: 'download', sortable: false },
    { key: 'upload', sortable: false },
  ]

  return {
    uploadTableColumns,
  }
}
