<template>
  <div>
    <categories-create @refetch-data="refetchData" />

    <!-- Retailer Catalogue Table -->
    <retailer-catalogue-table ref="retailerCatalogueTable" />

    <b-card
      class="mt-2"
      no-body
    >
      <validation-observer
        #default="{ invalid, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(upload)"
          @reset.prevent="resetForm"
        >

          <!-- Actions -->
          <b-row class="p-2 mb-4">
            <b-col>
              <h4>Upload Retailer Catalogue</h4>
            </b-col>
            <b-col sm="4">
              <b-button
                variant="secondary"
                class="float-right"
                size="sm"
                type="submit"
                :disabled="invalid"
              >
                <span class="text-nowrap">Upload</span>
              </b-button>

              <!-- Reset -->
              <b-button
                variant="outline-primary"
                class="float-right mx-1"
                size="sm"
                type="reset"
                :disabled="invalid"
              >
                <span class="text-nowrap">Reset</span>
              </b-button>
            </b-col>
          </b-row>

          <categories-list
            ref="categoryList"
            :catalogue-data="catalogueData"
            @refetch-data="refetchData"
            @append-catalogue-data="appendCatalogueData"
            @remove-catalogue-data="removeCatalogueData"
            @delete-category="deleteCategory"
          />
        </b-form>

      </validation-observer>
    </b-card>

    <!-- PREVIEW CSV -->
    <preview-retailer-catalogue-table
      v-if="catalogueTableData && catalogueTableData.length > 0"
      :catalogue-table-data="catalogueTableData"
    />

  </div>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import CategoriesCreate from '../../categories/categories-create/CategoriesCreate.vue'
import CategoriesList from '../../categories/categories-list/CategoriesList.vue'
import useRetailerCatalogueCreate from './useRetailerCatalogueCreate'
import RetailerCatalogueTable from '../retailer-catalogue-table/RetailerCatalogueTable.vue'
import retailerCatalogueStoreModule from '../../retailerCatalogueStoreModule'
import store from '@/store'
import router from '@/router'
import useCategoriesForm from '../../categories/categories-form/useCategoriesForm'
import PreviewRetailerCatalogueTable from '../preview-retailer-catalogue-table/PreviewRetailerCatalogueTable.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,

    CategoriesCreate,
    CategoriesList,

    //  Form Validation
    ValidationObserver,
    RetailerCatalogueTable,
    PreviewRetailerCatalogueTable,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { refs }) {
    const RETAILER_CATALOGUE_APP_STORE_MODULE_NAME = 'app-retailer-catalogue'

    // Register module
    if (!store.hasModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME, retailerCatalogueStoreModule)

    const {
      catalogueData,
      catalogueTableData,

      appendCatalogueData,
      removeCatalogueData,
      resetCatalogueData,
      validateRetailerCatalogue,
      uploadRetailerCatalogue,
    } = useRetailerCatalogueCreate()

    const {
      procCategory,
      procSubCategory,
    } = useCategoriesForm()

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetCatalogueData)

    const refetchData = () => setTimeout(() => refs.categoryList.$refs.refCategoryListTable.refresh(), 2000)

    const refetchRetailerCatalogue = () => setTimeout(() => refs.retailerCatalogueTable.$refs.refRetailerCatalogueTable.refresh(), 2000)

    const deleteRow = async (categoryId, type) => {
      const response = type === 1
        ? await procSubCategory({ operation: 'D', subcategory_id: categoryId, branchId: router.currentRoute.params.id })
        : await procCategory({ operation: 'D', category_id: categoryId, branchId: router.currentRoute.params.id })
      return response
    }

    const deleteCategory = (categoryId, type) => removeCatalogueData(categoryId) && deleteRow(categoryId, type) && refetchData() && refetchRetailerCatalogue()

    const upload = async () => {
      const response = await uploadRetailerCatalogue(catalogueData.value)
      if (response.status) {
        refetchRetailerCatalogue()
        refetchData()
      }
    }

    return {
      catalogueData,
      catalogueTableData,

      refetchData,
      appendCatalogueData,
      removeCatalogueData,
      validateRetailerCatalogue,
      deleteCategory,

      refFormObserver,
      upload,
      resetForm,
    }
  },
}
</script>
